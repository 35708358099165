import axios from "axios";

export default {
  namespaced: true,
  state: {
    dpto: {},
    dptos: [],
  },
  mutations: {
    setDpto(state, dpto) {
      state.dpto = dpto;
    },
    setDptos(state, data) {
      state.dptos = data;
    },
  },
  actions: {
    guardarDpto({ commit }, dpto) {
      commit("setDpto", dpto);
    },
    getDptos: async function ({ commit }, token) {
      let header = { Authorization: token };
      let configuracion = { headers: header };
      let d = null;

      await axios
        .get(`house/`, configuracion)
        .then(function (response) {
          d = response.data;
          commit("setDptos", d);
        })
        .catch(function (error) {
          console.log(error);
        });
      
    },
  },
};
