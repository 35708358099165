import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/modules/users'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

var router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      loggeado: true
    }
},
{
    path: '/login',
    name: 'Login',
    meta: {
      libre: true
    },
    component: () =>
        import ('../views/Login.vue'),
},
{
    path: '/view',
    name: 'View',
    meta: {
      loggeado: true
    },
    component: () =>
        import ('../views/View.vue'),
}
]
})
router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.libre)){
    next();
  } else if (store.state.usuario){
    //eslint-disable-next-line
    if (to.matched.some(record => record.meta.loggeado)){
      next();
    }
  } else {
    next({path:'/login'});
  }
})

export default router
