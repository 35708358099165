import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios';

Vue.config.productionTip = false

let back = window.location.host == 'localhost:8080' ? 'http://localhost:5000/v1/api/' : 'https://house-nuy-api.herokuapp.com/v1/api/';

axios.defaults.baseURL= back; 

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
